import React, { useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from "aws-amplify/utils";

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Header = (props) => {
  const { onDrawerToggle, menuName } = props;
  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          getEmail();
          break;
        case "signInWithRedirect_failure":
          setError("An error has occurred during the OAuth flow.");
          break;
        case "customOAuthState":
          setCustomState(payload.data); // this is the customState provided on signInWithRedirect function
          break;
      }
    });

    getEmail();

    return unsubscribe;
  }, []);

  async function getEmail() {
    try {
      const userAttributes = await fetchUserAttributes();
      setEmail(userAttributes.email);
    } catch (err) {
      setEmail('invalid email');
    }
  };

  const [email, setEmail] = React.useState("invalid email");

  return (
    <React.Fragment>
      <AppBar position='sticky' elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems='center'>
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={onDrawerToggle}
                edge='start'
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography color='inherit' variant='h6' component='h1'>
                {menuName}
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{ fontWeight: 'bold' }}
                component='h1'
              >
                {email}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title='SignOut'>
                <IconButton color="secondary" onClick={handleSignOut}>
                  <LogoutIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
  menuName: PropTypes.string.isRequired
};

export default Header;
