export default function copyToClipboard(text) {
  // Create a temporary textarea element
  const textarea = document.createElement('textarea');

  // Set the text content to the text to be copied
  textarea.value = text;

  // Make sure the textarea is not visible
  textarea.style.position = 'fixed';
  textarea.style.top = 0;
  textarea.style.left = 0;
  textarea.style.opacity = 0;

  // Append the textarea to the DOM
  document.body.appendChild(textarea);

  // Select the text within the textarea
  textarea.select();

  try {
    // Copy the selected text to the clipboard
    document.execCommand('copy');
    console.log('Text copied to clipboard:', text);
  } catch (err) {
    console.error('Unable to copy text to clipboard:', err);
  }

  // Remove the textarea from the DOM
  document.body.removeChild(textarea);
}
