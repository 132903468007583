import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getUser } from '../slices/currentUser'
import Amplify from '../Amplify'

const createCategories = (categories) => {
  let current_index = 0;
  for (let i = 0; i < categories.length; i++) {
    let category = categories[i];
    for (let j = 0; j < category.children.length; j++) {
      let child = category.children[j];
      child["index"] = current_index;
      current_index++;
    }
  }
  return categories;
}

const adminGroup = 'admin'
const categories = createCategories([
  {
    id: 'Dashboard',
    children: [
      {
        id: 'Dashboard',
        icon: <ListAltIcon />,
        path: '/dashboard'
      },
      {
        id: 'API Keys',
        icon: <ListAltIcon />,
        path: '/apikeys'
      },
    ],
    group: '*'
  },
  {
    id: 'Management',
    children: [
      {
        id: 'Customer',
        icon: <BusinessIcon />,
        active: true,
        path: '/customers'
      },
      {
        id: 'License code',
        icon: <LibraryBooksIcon />,
        path: '/licensecodes'
      },

      {
        id: 'VCA connection',
        icon: <AnalyticsIcon />,
        path: '/connections'
      },
      {
        id: 'User',
        icon: <PeopleIcon />,
        active: true,
        path: '/users'
      }
    ],
    group: adminGroup
  },
  {
    id: 'Log',
    children: [
      {
        id: 'Connection',
        icon: <ListAltIcon />,
        path: '/connection-log'
      },
      {
        id: 'Checkout',
        icon: <FactCheckIcon />,
        path: '/checkout-log'
      }
    ],
    group: adminGroup
  }
]);

const Navigator = (props) => {
  const onMenuNameChange = props.onMenuNameChange;
  const { ...other } = props;
  const dispatch = useDispatch();
  const enqueueSnackbar = useSnackbar();
  const current_user = useSelector((state) => state.currentUser)
  const initFetch = React.useCallback(() => {
    (async () => {
      try {
        await dispatch(getUser()).unwrap();
      } catch (err) {
        console.log(err)
      }
    })();
  }, [dispatch, enqueueSnackbar]);

  if (other.onMenuNameChange) {
    delete other.onMenuNameChange;
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuNameChange = (id, index) => {
    if (onMenuNameChange) {
      onMenuNameChange(id);
    }
    setSelectedIndex(index);
  };

  const setMenuFromPath = () => {
    const path = window.location.pathname;
    for (let i = 0; i < categories.length; i++) {
      let category = categories[i];
      for (let j = 0; j < category.children.length; j++) {
        let child = category.children[j];
        if (child.path == path) {
          handleMenuNameChange(
            child.id,
            child.index
          );
        }
      }
    }
  }

  React.useEffect(() => {
    initFetch();
    setMenuFromPath();
  }, [initFetch]);

  const navigate = useNavigate();
  return (
    <Drawer variant='permanent' {...other}>
      <List disablePadding>
        <ListItem sx={{ fontSize: 16 }}>Console</ListItem>

        {categories
          .filter(({ group }) => {
            return current_user.groups.indexOf(group) !== -1 || group == '*'
          })
          .map(({ id, children }) => (
            <Box key={id} >
              <ListItem >
                <ListItemText>{id}</ListItemText>
              </ListItem>

              {children.map(({ id: childId, icon, active, path, index }) => (
                <ListItem disablePadding key={childId}>
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={(e) => {
                      handleMenuNameChange(e.target.outerText, index);
                      navigate(path, { replace: true });
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
      </List>
    </Drawer>
  );
};

Navigator.propTypes = {
  onMenuNameChange: PropTypes.func.isRequired
};

export default Navigator;
