import React from 'react';
import View from '../../table/view'
import { createLicenseCode, deleteLicenseCode, getLicenseCodes, updateLicenseCode } from '../../slices/license-codes'

const LicenseCode = () => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'code', formEnabled: true, type: 'text', headerName: 'Code', sortable: true, minWidth: 80 },
    { field: 'name', formEnabled: true, type: 'text', headerName: 'Name', sortable: true, filter: true, minWidth: 150, editable: true },
    { field: 'createdAt', headerName: 'Created At', sortable: true, minWidth: 210 },
    { field: 'updatedAt', headerName: 'Updated At', sortable: true, minWidth: 100 },
  ];
  return (
    <>
      <View
        columnDefs={columnDefs}
        deleteFunction={deleteLicenseCode}
        getFunction={getLicenseCodes}
        updateFunction={updateLicenseCode}
        createFunction={createLicenseCode}
        stateSelector={(state) => state.licenseCodes}
        addEnabled={true}
        deleteEnabled={true}
      />
    </>
  )
}

export default LicenseCode;
