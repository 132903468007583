import React from 'react';
import View from '../../table/view'
import { createUser, deleteUser, getUsers, updateUser } from '../../slices/users'

const User = () => {
  const columnDefs = [
    { field: 'email', headerName: 'Email', sortable: true, filter: true, width: 250 },
    { field: 'username', headerName: 'Username', sortable: true, filter: true, width: 250 },
    { field: 'groups', headerName: 'Groups', sortable: true, filter: true, width: 200 },
    { field: 'createdAt', headerName: 'Created At', sortable: true, filter: true, width: 250 }
  ];
  return (
    <>
      <View
        columnDefs={columnDefs}
        deleteFunction={deleteUser}
        getFunction={getUsers}
        updateFunction={updateUser}
        createFunction={createUser}
        stateSelector={(state) => state.users}
        addEnabled={false}
        deleteEnabled={false}
        getRowId={(row) => {
          return row.username
        }}
      />
    </>
  )
}

export default User;
