import React, { forwardRef, useEffect, useMemo, useCallback, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LoadingBar from 'react-top-loading-bar';
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid';
import LoadingState from '../utilities/loading_state'
import CircularProgress from '@mui/material/CircularProgress';

const TableList = (props, ref) => {
  const loadingRef = React.useRef(null);
  const gridRef = React.useRef(null);

  const rows = useSelector(state => props.stateSelector(state).items);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initFetch = useCallback(() => {
    (async () => {
      try {
        loadingRef.current.staticStart();
        if (props.getFunction) {
          await dispatch(props.getFunction()).unwrap();
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        if (loadingRef.current) {
          loadingRef.current.complete();
        }
      }
    })();
  }, [dispatch, enqueueSnackbar]);

  const initUpdateValue = useCallback((new_value, old_value) => {
    (async (new_value, old_value) => {
      try {
        loadingRef.current.staticStart();
        let object_to_send = {};
        for (let k of Object.keys(old_value)) {
          if (new_value[k] !== old_value[k] || k == 'id') {
            object_to_send[k] = new_value[k];
          }
        }
        await dispatch(props.updateFunction(object_to_send)).unwrap();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        if (loadingRef.current) {
          loadingRef.current.complete();
        }
      }
    })(new_value, old_value);
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const reload = () => {
    initFetch();
  };

  const quickSearch = (value) => {
    gridRef.current.api.setQuickFilter(value);
  };

  useImperativeHandle(ref, () => ({
    reload: () => {
      reload();
    },
    quickSearch: (value) => {
      quickSearch(value);
    }
  }));

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const loadingState = useSelector(state => props.stateSelector(state).loading_state)
  return (
    <div>
      {loadingState == LoadingState.loading ?
        <CircularProgress />
        :
        <DataGrid
          cacheQuickFilter={true}
          quickFilterText={props.quickFilterText}
          ref={gridRef}
          columns={props.columnDefs}
          paginationAutoPageSize={true}
          onCellEditStop={(params, event) => {
            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              event.defaultMuiPrevented = true;
            }
          }}
          pagination={true}
          animateRows={true}
          blockLoadDebounceMillis={1000}
          rows={rows}
          suppressExcelExport={true}
          density="compact"
          readOnlyEdit={true}
          checkboxSelection
          disableColumnMenu
          disableRowSelectionOnClick
          getRowId={props.getRowId}
          processRowUpdate={(newRow, oldRow) => {
            initUpdateValue(newRow, oldRow);
            return newRow
          }}
          onProcessRowUpdateError={(error) => {
            console.log('Encountered ' + error + 'while ')
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            props.onSelectionChanged(newRowSelectionModel)
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          slots={{ toolbar: props.toolbar }}
        ></DataGrid>
      }
      <LoadingBar color='#00ffaa' height={1} ref={loadingRef} />
    </div>
  );
};

export default forwardRef(TableList);
