import { generateClient } from 'aws-amplify/api';

const MakeGraphQLService = (list, create, update, del) => {
  let client = generateClient();
  const getNestedKey = (object) => {
    const object_keys = Object.keys(object.data)
    return object.data[object_keys[0]]
  }
  const list_api = (filter) => {
    return client.graphql({
      query: list,
      variables: {
        filter: filter
      }
    })
      .then(data => {
        const items = getNestedKey(data).items;
        return items;
      })
      .catch(err => console.log(err))
  };

  const create_api = (data) => {
    return client.graphql({
      query: create,
      variables: {
        input: data
      }
    })
      .then(data => {
        return getNestedKey(data)
      })
      .catch(err => console.log(err))
  };

  const update_api = (new_value) => {
    return client.graphql({
      query: update,
      variables: {
        input: new_value
      }
    })
      .then(data => {
        return getNestedKey(data)
      })
      .catch(err => console.log(err))
  };

  const get = () => {
    return {};
  }

  const del_api = (id) => {
    return client.graphql({
      query: del,
      variables: {
        input: {
          id
        }
      }
    })
      .then(data => {
        const items = getNestedKey(data)
        console.log(items)
        return items;
      })
      .catch(err => console.log(err))
  };

  return {
    getList: list_api,
    get,
    create: create_api,
    update: update_api,
    del: del_api
  }
}

export default MakeGraphQLService;
