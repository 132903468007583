import React, { useCallback } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import GridToolbar from './grid-toolbar';
import Paper from '@mui/material/Paper';
import TableList from './table-list';
import TableAdd from './table-add'

const View = (props) => {
  const tableRef = React.useRef(null);

  const [selection, setSelection] = React.useState([])
  const [isAddOpened, setIsAddOpened] = React.useState(false);
  const onAddOpenChanged = useCallback((isOpened) => {
    setIsAddOpened(isOpened);
  }, []);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const add_columns = props.columnDefs.filter(item => item.formEnabled)

  const initFetch = useCallback(() => {
    (async () => {
      try {
        loadingRef.current.staticStart();
        if (props.getFunction) {
          await dispatch(props.getFunction()).unwrap();
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        if (loadingRef.current) {
          loadingRef.current.complete();
        }
      }
    })();
  }, [dispatch, enqueueSnackbar]);

  const loadingRef = React.useRef(null);
  const [quickFilterText, setQuickFilterText] = React.useState("");
  const deleteItems = useCallback((items) => {
    (async () => {
      try {
        loadingRef.current.staticStart();
        for (let id of items) {
          await dispatch(props.deleteFunction(id)).unwrap();
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        if (loadingRef.current) {
          loadingRef.current.complete();
        }
      }
    })();
  }, [dispatch, enqueueSnackbar]);

  function CustomToolbar(toolbar_props) {
    return <GridToolbar
      name={props.name}
      onAddOpenChanged={() => setIsAddOpened(true)}
      onDeleteClicked={() => deleteItems(selection)}
      onReloadClicked={() => initFetch()}
      addEnabled={props.addEnabled}
      deleteEnabled={props.deleteEnabled}
      quickFilterCallback={(value) => setQuickFilterText(value)}
      onExportClicked={toolbar_props.onExportClicked}
    />
  }
  return (
    // <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
    <AppBar
      position='static'
      color='background'
      elevation={0}
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', overflow: 'none' }}
    >
      <div>
        <TableList
          columnDefs={props.columnDefs}
          getFunction={props.getFunction}
          updateFunction={props.updateFunction}
          deleteFunction={props.updateFunction}
          ref={tableRef}
          stateSelector={props.stateSelector}
          quickFilterText={quickFilterText}
          getRowId={props.getRowId ? props.getRowId : (row) => { return row.id }}
          toolbar={CustomToolbar}
          onSelectionChanged={
            (event) => {
              if (props.notifySelection) {
                props.notifySelection(event);
              }
              setSelection(event)
            }
          } />
        <TableAdd
          isOpened={isAddOpened}
          onOpenChanged={onAddOpenChanged}
          columns={add_columns}
          createFunction={props.createFunction}
          name={props.name}
        />
        <LoadingBar color='#00ffaa' height={1} ref={loadingRef} />
      </div>
    </AppBar>
    // </Paper>
  );
};

export default View;
