import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const CustomerService = MakeGraphQLService(
  queries.listCustomers,
  mutations.createCustomer,
  mutations.updateCustomer,
  mutations.deleteCustomer
);

export default CustomerService;
