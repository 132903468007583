import React, { useState, useEffect, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalSite, setTotalSite] = useState(0);

  const dispatch = useDispatch();

  const sites = useSelector((state) => state.sites);
  const totalLicense = 0;
  const totalUsedLicense = 0;
  const totalCore = 0;
  const dashboardData = {};
  const getSitesStatus = 1;
  const getDashboardsStatus = 1;

  useEffect(() => {
    setTotalSite(sites.length);
  }, [sites]);

  useEffect(() => { }, [dashboardData]);

  useEffect(() => {
    if (getSitesStatus === 0 || getDashboardsStatus === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [getSitesStatus, getDashboardsStatus]);

  const makeCard = (title, data) => {
    return (
      <Grid item>
        <Card variant="outlined" sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="h3" sx={{ mb: 1.5 }} color="text.secondary">
              {data}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  };

  return (
    <Box sx={{
      justifyContent: 'center', display: 'flex',
      flexDirection: 'row',
    }}>
      <Grid container spacing={2}>
        {makeCard("Total Site", totalSite)}
        {makeCard("Total License", totalLicense)}
        {makeCard("Total Used License", totalUsedLicense)}
        {makeCard("Total Core", totalCore)}
        <div className='center-loader'>
          <Loader type='TailSpin' color='#cc1417' height={100} width={100} visible={isLoading} />
        </div>
      </Grid>
    </Box>
  );
};

export default Dashboard;
