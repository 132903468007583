import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CurrentUserService from '../services/currentUser';

const initialState = {
  user: '',
  groups: []
}

export const getUser = createAsyncThunk('USERS/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await CurrentUserService.getUser();
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

const currentUserSlice = createSlice({
  name: 'USER',
  initialState,
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      state.user = action.payload.user
      state.groups = action.payload.groups
    },
  }
});

const { reducer } = currentUserSlice;
export default reducer;
