import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Container } from '@mui/material';
import { Google } from '@mui/icons-material'; // Import the Google icon

const SignInForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle sign-in logic here
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Cloud Licensing
        </Typography>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1rem' }}>
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ marginTop: '1rem' }}>
            Sign In
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ marginTop: '1rem' }}
            startIcon={<Google />}
            onClick={() => props.googleSignIn()}
          >Google Sign-in</Button>
        </form>
      </Paper>
    </Container>
  );
};

export default SignInForm;

