import { fetchAuthSession } from 'aws-amplify/auth';

async function getUser() {
  const session_data = await fetchAuthSession();
  return {
    user: session_data.tokens.accessToken.payload.username,
    groups: session_data.tokens.accessToken.payload["cognito:groups"]
  }
}

const CurrentUserService = {
  getUser
};

export default CurrentUserService;
