import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ConnectionLogService from '../services/connection-log';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createConnectionLog = createAsyncThunk(
  'CONNECTIONLOG/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await ConnectionLogService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getConnectionLogs = createAsyncThunk(
  'CONNECTIONLOG/GETLIST',
  async (param1, { rejectWithValue }) => {
    try {
      const res = await ConnectionLogService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getConnectionLog = createAsyncThunk(
  'CONNECTIONLOG/GET',
  async (id, { rejectWithValue }) => {
    try {
      const res = await ConnectionLogService.get(id);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const updateConnectionLog = createAsyncThunk(
  'CONNECTIONLOG/UPDATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await ConnectionLogService.update(data);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteConnectionLog = createAsyncThunk(
  'CONNECTIONLOG/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await ConnectionLogService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const connectionLogSlice = createSlice({
  name: 'CONNECTIONLOG',
  initialState,
  extraReducers: {
    [createConnectionLog.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getConnectionLogs.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getConnectionLogs.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getConnectionLog.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getConnectionLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateConnectionLog.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [updateConnectionLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteConnectionLog.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [deleteConnectionLog.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = connectionLogSlice;
export default reducer;
