import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download'
import { useGridApiContext } from '@mui/x-data-grid';

const GridToolbar = (props) => {
  const apiRef = useGridApiContext();

  return (
    <Toolbar>
      <Grid container spacing={2} alignItems='center'>
        {
          props.name ?
            <Grid item>
              <h2>{props.name}</h2>
            </Grid> : <></>
        }
        <Grid item xl />
        <Grid item>
          <SearchIcon color='inherit' sx={{ display: 'block' }} />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            placeholder='Search by all colunms'
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: 'default' }
            }}
            variant='standard'
            onChange={(event) => props.quickFilterCallback(event.target.value)}
          />
        </Grid>
        <Grid item>
          <Tooltip title='Download'>
            <IconButton onClick={() => apiRef.current.exportDataAsCsv()}>
              <DownloadIcon color='inherit' sx={{ display: 'block' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Reload'>
            <IconButton onClick={() => props.onReloadClicked()}>
              <RefreshIcon color='inherit' sx={{ display: 'block' }} />
            </IconButton>
          </Tooltip>
          {
            props.addEnabled ?
              <Tooltip title='Add'>
                <IconButton onClick={() => props.onAddOpenChanged(true)}>
                  <AddIcon color='inherit' sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip> : <></>
          }
          {
            props.deleteEnabled ?
              <Tooltip title='Delete'>
                <IconButton onClick={() => props.onDeleteClicked()}>
                  <DeleteIcon color='inherit' sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip> : <></>
          }
        </Grid>
      </Grid>
    </Toolbar>
  )
}
export default GridToolbar;
