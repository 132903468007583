import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './slices/customers';
import tokenReducer from './slices/tokens';
import tokenLicenseReducer from './slices/token-license';
import licenseCodeReducer from './slices/license-codes';
import siteReducer from './slices/sites';
import connectionReducer from './slices/connections';
import checkoutLogReducer from './slices/checkout-log';
import userReducer from './slices/users';
import currentUserReducer from './slices/currentUser';

const reducer = {
  currentUser: currentUserReducer,
  tokens: tokenReducer,
  tokenLicenses: tokenLicenseReducer,
  customers: customerReducer,
  licenseCodes: licenseCodeReducer,
  sites: siteReducer,
  connections: connectionReducer,
  checkoutLog: checkoutLogReducer,
  users: userReducer
};

const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
});

export default store;
