import * as React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import LoadingBar from 'react-top-loading-bar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const notiInfo = {
  wait: {
    message: 'Waiting for ITEM create.',
    type: { variant: 'info' }
  },
  success: {
    message: 'ITEM successfully created.',
    type: { variant: 'success' }
  }
};

const TableAdd = ({ isOpened, onOpenChanged, createFunction, columns, name }) => {
  const initInputs = {};
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (column.type == 'boolean') {
      initInputs[column.field] = false
    } else {
      initInputs[column.field] = ""
    }
  }

  const getCheckTypeField = (values) => {
    const arr = [];

    for (const key in values) {
      if (typeof values[key] === 'number') {
        arr.push(key);
      }
    }
    return arr;
  };


  const [inputs, setInputs] = React.useState(initInputs);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const checkBoxItems = getCheckTypeField(inputs);
  const loadingRef = React.useRef(null);

  const dispatch = useDispatch();
  const handleClose = () => {
    onOpenChanged(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const notiMessage = (info) => {
    const { message, type = { variant: 'error' } } = info;
    enqueueSnackbar(message.replace('ITEM', name), type);
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      notiMessage(notiInfo.wait);
      return;
    }

    let inputs_copy = JSON.parse(JSON.stringify(inputs))
    const properties = Object.keys(inputs_copy);
    properties.forEach((property) => {
      const column = columns.find((column) => {
        return column.field == property;
      })
      const value = inputs_copy[property];
      if (column.type == 'singleselect') {
        inputs_copy[property] = column.valueLookup[value];
      }
    })
    setIsSubmitting(true);
    loadingRef.current.staticStart();

    (async () => {
      try {
        await dispatch(createFunction(inputs_copy)).unwrap();
        notiMessage(notiInfo.success);
      } catch (err) {
        notiMessage({ message: err.message });
      } finally {
        handleClose();
        setIsSubmitting(false);
        loadingRef.current.complete();
      }
    })();
  };

  const handleChange = (id, value) => {
    setInputs({ ...inputs, [id]: value });
  };

  const getName = (item) => {
    return item.headerName ? item.headerName : item.field;
  }

  return (
    <div>
      <BootstrapDialog
        open={isOpened}
        onClose={handleClose}
      >
        <DialogTitle id='customized-dialog-title'>
          {"Create a " + name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>

          <FormGroup>
            {
              columns.map((item) => {
                if (item.type == 'singleselect') {
                  return (
                    <FormControl key={item.field} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">{getName(item)}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id={item.field}
                        value={inputs[item.field]}
                        label={getName(item)}
                        onChange={(e) => handleChange(item.field, e.target.value)}
                        key={item.field}
                      >
                        {
                          item.valueOptions.map((item, index) => {
                            return <MenuItem key={index} id={index} value={index}>{item}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  )
                } else if (item.type === 'boolean') {
                  return (
                    <FormControlLabel key={item.field} control={<Checkbox
                      checked={inputs[item.field]}
                      onChange={(e) => { handleChange(item.field, e.target.checked) }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label={getName(item)} />
                  )
                } else {
                  return (
                    <FormControl key={item.field} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <TextField
                        autoFocus
                        required
                        margin='dense'
                        id={item.field}
                        key={item.field}
                        label={getName(item)}
                        type={item.type}
                        fullWidth
                        variant='standard'
                        size='small'
                        value={inputs[item.id]}
                        onChange={(e) => {
                          if (item.type == 'number') {
                            handleChange(item.field, Number(e.target.value))
                          } else {
                            handleChange(item.field, e.target.value)
                          }
                        }}
                      />
                    </FormControl>
                  )
                }
              })
            }
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>
            Create
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <LoadingBar color='#00ffaa' height={1} ref={loadingRef} />
    </div>
  );
};

TableAdd.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onOpenChanged: PropTypes.func.isRequired
};

export default TableAdd;
