import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TokenService from '../services/tokens';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createToken = createAsyncThunk(
  'TOKENS/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TokenService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getTokens = createAsyncThunk(
  'TOKENS/GETLIST',
  async (param, { rejectWithValue }) => {
    try {
      const res = await TokenService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getToken = createAsyncThunk('TOKENS/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await TokenService.get(id);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const getTokensForSites = createAsyncThunk(
  'TOKENS/GETLISTFORSITEs',
  async (sites, { rejectWithValue }) => {
    try {
      const res = await TokenService.getList({
        or: sites.map(site => {
          return {
            tokenSiteId: {
              eq: site
            }
          }
        })
      });
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const updateToken = createAsyncThunk(
  'TOKENS/UPDATE',
  async ( data , { rejectWithValue }) => {
    try {
      const res = await TokenService.update(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteToken = createAsyncThunk(
  'TOKENS/DELETE',
  async (id, { rejectWithValue }) => {
    try {
      await TokenService.del(id);
      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const tokenSlice = createSlice({
  name: 'TOKEN',
  initialState,
  extraReducers: {
    [createToken.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getTokens.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getTokens.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getToken.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getToken.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateToken.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [updateToken.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteToken.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [deleteToken.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    },
    [getTokensForSites.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getTokensForSites.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
  }
});

const { reducer } = tokenSlice;
export default reducer;
