import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const ConnectionLogService = MakeGraphQLService(
  queries.listConnectionLogs,
  mutations.createConnectionLog,
  mutations.updateConnectionLog,
  mutations.deleteConnectionLog
);

export default ConnectionLogService;
