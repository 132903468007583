import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const SiteService = MakeGraphQLService(
  queries.listSites,
  mutations.createSite,
  mutations.updateSite,
  mutations.deleteSite
);

export default SiteService;
