import React, { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './navigator';
import Header from './header';
import Customer from './management/customer';
import Dashboard from './dashboard/Dashboard';
import Tokens from './dashboard/tokens'
import TokenLicenses from './dashboard/TokenLicenses.js'
import ApiKeys from './dashboard/ApiKeys.js'
import LicenseCode from './management/license-code';
import Site from './management/site';
import Connection from './management/connection';
import User from './management/user';
import LogConnection from './maintenance/connection-log';
import CheckoutLog from './maintenance/checkout-log';

const drawerWidth = 200;

const Console = (props) => {
  const [menuName, setMenuName] = React.useState('Customer');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(props.theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuNameChanged = useCallback((name) => {
    if (name) {
      setMenuName(name);
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {isSmUp ? null : (
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onMenuNameChange={handleMenuNameChanged}
          />
        )}

        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: 'block', xs: 'none' } }}
          onMenuNameChange={handleMenuNameChanged}
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Header onDrawerToggle={handleDrawerToggle} menuName={menuName} />

        <Box component='main' sx={{ flex: 1, py: 3, px: 3 }}>
          <Routes>
            <Route path='/' element={<Customer />} />
            <Route path='/customers' element={<Customer />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/tokens' element={<Tokens />} />
            <Route path='/apikeys' element={<ApiKeys />} />
            <Route path='/tokenlicenses' element={<TokenLicenses />} />
            <Route path='/licensecodes' element={<LicenseCode />} />
            <Route path='/sites' element={<Site />} />
            <Route path='/connections' element={<Connection />} />
            <Route path='/users' element={<User />} />
            <Route path='/connection-log' element={<LogConnection />} />
            <Route path='/checkout-log' element={<CheckoutLog />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default Console;
