import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const TokenLicenseService = MakeGraphQLService(
  queries.listTokenLicenses,
  mutations.createTokenLicense,
  mutations.updateTokenLicense,
  mutations.deleteTokenLicense
);

export default TokenLicenseService;
