import React from 'react';
import Sites from '../management/site'
import Tokens from '../dashboard/tokens'
import TokenLicense from '../dashboard/TokenLicenses'
import { getTokensForSites } from '../../slices/tokens'
import { getTokenLicensesForTokens } from '../../slices/token-license'
import { useDispatch } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

const ApiKeys = () => {
  const [showTokens, setShowTokens] = React.useState(false)
  const [showLicenses, setShowLicenses] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = [
    'Select Site(s)',
    'Select Token(s)',
    'Select License(s)'
  ]
  const dispatch = useDispatch();
  return (
    <>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep ? true : false}>
            <StepButton color="inherit" >
              <h2>{label}</h2>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Sites notifySelection={(event) => {
        if (event.length == 0) {
          setShowTokens(false);
          setShowLicenses(false);
          setActiveStep(0)
        } else {
          setShowTokens(true);
          setActiveStep(1)
        }
        if (event.length > 0) {
          (async () => {
            try {
              await dispatch(getTokensForSites(event)).unwrap();
            } catch (err) {
              console.log(err)
            }
          })();
        }
      }} />
      {showTokens ? <Tokens notifySelection={(event) => {
        console.log('selected tokens: ' + String(event))
        if (event.length == 0) {
          setShowLicenses(false);
          setActiveStep(1)
        } else {
          setShowLicenses(true);
          setActiveStep(2)
        }
        if (event.length > 0) {
          (async () => {
            try {
              await dispatch(getTokenLicensesForTokens(event)).unwrap();
            } catch (err) {
              console.log(err)
            }
          })();
        }
      }} /> : <></>}
      {showLicenses ? <TokenLicense /> : <></>}
    </>
  )
}

export default ApiKeys;
