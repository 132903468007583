import React from 'react';
import View from '../../table/view'
import { createSite, deleteSite, getSites, updateSite } from '../../slices/sites'

const Site = ({ notifySelection }) => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'name', editable: true, formEnabled: true, headerName: 'Name', sortable: true, minWidth: 80 },
    { field: 'address1', editable: true, formEnabled: true, headerName: 'Address 1', sortable: true, minWidth: 80 },
    { field: 'address2', editable: true, formEnabled: true, headerName: 'Address 2', sortable: true, minWidth: 80 },
    { field: 'owner', editable: false, formEnabled: false, headerName: 'Owner', sortable: true, minWidth: 80 },
    { field: 'createdAt', headerName: 'Created At', sortable: true, minWidth: 210 },
    { field: 'updatedAt', headerName: 'Updated At', sortable: true, minWidth: 100 },
  ];
  return (
    <>
      <View
        name="Site"
        columnDefs={columnDefs}
        deleteFunction={deleteSite}
        getFunction={getSites}
        updateFunction={updateSite}
        createFunction={createSite}
        stateSelector={(state) => state.sites}
        addEnabled={true}
        deleteEnabled={true}
        notifySelection={(event) => {
          notifySelection(event);
        }}
      />
    </>
  )
}

export default Site;
