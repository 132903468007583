import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api'

async function getHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: (await fetchAuthSession()).tokens.accessToken.toString()
  }
}

async function getUsers() {
  const limit = 10;
  const apiName = 'AdminQueries'
  let path = '/listUsers';
  let options = {
    queryStringParameters: {
      "groupname": "Editors",
      "limit": limit,
    },
    headers: await getHeaders()
  }
  const response = get({ apiName, path, options }).response;
  const body = (await (await response).body.json());
  return body.Users.map(item => {
    return {
      "email": item.Attributes.find(value => value.Name == 'email').Value,
      "username": item.Username,
      "createdAt": item.UserCreateDate
    }
  })
}
async function getGroupsForUser(username) {
  const limit = 10;
  const apiName = 'AdminQueries'
  let path = '/listGroupsForUser'+ "?username=" + username;
  let options = {
    queryStringParameters: {
      "username": username,
      "limit": limit,
    },
    headers: await getHeaders()
  }
  const response = get({ apiName, path, options }).response;
  const body = (await (await response).body.json());
  return body.Groups
}

async function getList() {
  const users = await getUsers();
  const user_group_list = [];
  for (let i = 0 ; i < users.length ; i++) {
    const user = users[i];
    const groups = await getGroupsForUser(user.username)
    user_group_list.push({
      username: user.username,
      email: user.email,
      groups: groups.map(item => item.GroupName).join(","),
      createdAt: user.createdAt
    })
  }
  return user_group_list
}

const getUser = () => {
  throw new Error("getting a specific user is not supported")
};

const create = () => {
  throw new Error("Creating a user is not supported")
}

const update = () => {
  throw new Error("Updating a user is not supported")
};

const del = () => {
  throw new Error("Deleting a user is not supported")
};

const UserService = {
  getList,
  getUser,
  create,
  update,
  del
};

export default UserService;
