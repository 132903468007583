import React from 'react';
import { useSelector } from 'react-redux';
import View from '../../table/view'
import { createTokenLicense, deleteTokenLicense, getTokenLicenses, updateTokenLicense } from '../../slices/token-license'

const TokenLicense = () => {
  const tokens = useSelector((state) => state.tokens.items)
  const licenses = useSelector((state) => state.licenseCodes.items)
  const columnDefs = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      minWidth: 400,
      checkboxSelection: false,
    },
    {
      field: 'tokenLicenseTokenId',
      headerName: 'Token',
      formEnabled: true,
      type: "singleselect",
      sortable: true,
      minWidth: 80,
      valueOptions: tokens.map((item) => item.name),
      valueLookup: tokens.map((item) => item.id),
      valueGetter: (value, _) => {
        const token = tokens.find((item) => { return item.id == value });
        try {
          return token.name;
        } catch (err) {
          return "Unknown";
        }
      }
    },
    {
      field: 'tokenLicenseLicenseId',
      headerName: 'License',
      formEnabled: true,
      type: "singleselect",
      sortable: true,
      minWidth: 80,
      valueOptions: licenses.map((item) => item.name),
      valueLookup: licenses.map((item) => item.id),
      valueGetter: (value, _) => {
        const license = licenses.find((item) => { return item.id == value });
        try {
          return license.name;
        } catch (err) {
          return "Unknown";
        }
      }
    },
    { field: 'channels', headerName: 'Channels', formEnabled: true, sortable: true, minWidth: 80, type: 'number', editable: true },
    { field: 'channelsInUse', headerName: 'Channels in use', formEnabled: false, sortable: true, minWidth: 80, type: 'number', editable: false }
  ];
  return (
    <>
      <View
        name="License"
        columnDefs={columnDefs}
        deleteFunction={deleteTokenLicense}
        updateFunction={updateTokenLicense}
        createFunction={createTokenLicense}
        stateSelector={(state) => state.tokenLicenses}
        addEnabled={true}
        deleteEnabled={true}
      />
    </>
  )
}

export default TokenLicense;
