import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const LicenseService = MakeGraphQLService(
  queries.listLicenses,
  mutations.createLicense,
  mutations.updateLicense,
  mutations.deleteLicense
);

export default LicenseService;
