import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LicenseCodeService from '../services/license-code';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loading_state: LoadingState.idle
};

export const createLicenseCode = createAsyncThunk(
  'LICENSECODES/CREATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await LicenseCodeService.create(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getLicenseCodes = createAsyncThunk(
  'LICENSECODES/GETLIST',
  async (param1, { rejectWithValue }) => {
    try {
      const res = await LicenseCodeService.getList();
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const getLicenseCode = createAsyncThunk(
  'LICENSECODES/GET',
  async (code, { rejectWithValue }) => {
    try {
      const res = await LicenseCodeService.get(code);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const updateLicenseCode = createAsyncThunk(
  'LICENSECODES/UPDATE',
  async ( new_value , { rejectWithValue }) => {
    try {
      const res = await LicenseCodeService.update(new_value);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteLicenseCode = createAsyncThunk(
  'LICENSECODES/DELETE',
  async (code, { rejectWithValue }) => {
    try {
      console.log('Attempting to delete ' + code)
      await LicenseCodeService.del(code);
      return { code };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

const licenseCodeSlice = createSlice({
  name: 'LICENSECODE',
  initialState,
  extraReducers: {
    [createLicenseCode.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getLicenseCodes.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getLicenseCodes.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getLicenseCode.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getLicenseCode.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateLicenseCode.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [updateLicenseCode.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteLicenseCode.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [deleteLicenseCode.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = licenseCodeSlice;
export default reducer;
