import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const ConnectionService = MakeGraphQLService(
  queries.listConnections,
  mutations.createConnection,
  mutations.updateConnection,
  mutations.deleteConnection
);

export default ConnectionService;
