import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const CheckoutLogService = MakeGraphQLService(
  queries.listCheckoutLogs,
  mutations.createCheckoutLog,
  mutations.updateCheckoutLog,
  mutations.deleteCheckoutLog
);

export default CheckoutLogService;
