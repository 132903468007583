import MakeGraphQLService from './make-graphql-service'
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const TokenService = MakeGraphQLService(
  queries.listTokens,
  mutations.createToken,
  mutations.updateToken,
  mutations.deleteToken
);

export default TokenService;
