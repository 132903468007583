import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SiteService from '../services/site';
import LoadingState from '../utilities/loading_state'

const initialState = {
  items: [],
  loadingState: LoadingState.idle
};

export const createSite = createAsyncThunk('SITES/CREATE', async (data, { rejectWithValue }) => {
  try {
    const res = await SiteService.create(data);
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const getSites = createAsyncThunk('SITES/GETLIST', async (param1, { rejectWithValue }) => {
  try {
    const res = await SiteService.getList();
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const getSite = createAsyncThunk('SITES/GET', async (id, { rejectWithValue }) => {
  try {
    const res = await SiteService.get(id);
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

export const updateSite = createAsyncThunk(
  'SITES/UPDATE',
  async (data, { rejectWithValue }) => {
    try {
      const res = await SiteService.update(data);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ ...err, ...err.response.data });
    }
  }
);

export const deleteSite = createAsyncThunk('SITES/DELETE', async (id, { rejectWithValue }) => {
  try {
    await SiteService.del(id);
    return { id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue({ ...err, ...err.response.data });
  }
});

const siteSlice = createSlice({
  name: 'SITE',
  initialState,
  extraReducers: {
    [createSite.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [getSites.pending]: (state, action) => {
      return {
        items: [],
        loading_state: LoadingState.loading
      };
    },
    [getSites.fulfilled]: (state, action) => {
      return {
        items: [...action.payload],
        loading_state: LoadingState.ready
      };
    },
    [getSite.pending]: (state, action) => {
      state.loading_state = LoadingState.loading
    },
    [getSite.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload[0]
      };
    },
    [updateSite.fulfilled]: (state, action) => {
      const index = state.items.findIndex((user) => user.id === action.payload.id);
      state.items[index] = {
        ...state[index],
        ...action.payload
      };
    },
    [deleteSite.fulfilled]: (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    }
  }
});

const { reducer } = siteSlice;
export default reducer;
