import React from 'react';
import View from '../../table/view'
import { createCheckoutLog, deleteCheckoutLog, getCheckoutLogs, updateCheckoutLog } from '../../slices/checkout-log'

const CheckoutLog = () => {
  const columnDefs = [
    { field: 'id', headerName: 'Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'endTime', headerName: 'End Time', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'startTime', headerName: 'Start Time', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'status', headerName: 'Status', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'channels', headerName: 'Channels', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'licenseCode', headerName: 'License Code', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'tokenId', headerName: 'Token Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'connectionId', headerName: 'Connection Id', sortable: true, minWidth: 80, checkboxSelection: true },
    { field: 'checkoutId', headerName: 'Checkout Id', sortable: true, minWidth: 80, checkboxSelection: true },
  ];
  return (
    <>
      <View
        columnDefs={columnDefs}
        deleteFunction={deleteCheckoutLog}
        getFunction={getCheckoutLogs}
        updateFunction={updateCheckoutLog}
        createFunction={createCheckoutLog}
        stateSelector={(state) => state.checkoutLog}
        addEnabled={false}
        deleteEnabled={false}
      />
    </>
  )
}

export default CheckoutLog;
